<template>
  <v-container>
    <v-row class="sc-title mt-7" justify="space-between">
      <v-col sm="6" md="8">
        <div>
          <h1>EVALUATION PREP</h1>
        </div>
      </v-col>
      <v-col md="2" sm="6">
        <div class="text-right">
          <v-btn
            color="primary"
            @click="
              $router.push({
                name: 'EvaluationDetail',
                params: { id: $route.params.id },
              })
            "
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <customer-info />
    <v-row style="margin-bottom: 100px">
      <v-col cols="12">
        <v-row justify="center">
          <v-col cols="12" sm="12" md="8">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                label="Customer GPA"
                v-model="evaluation_info.customerGPA"
              ></v-text-field>
              <v-text-field
                label="GeoZone"
                v-model="evaluation_info.geoZone"
              ></v-text-field>
              <v-text-field
                label="Lead Source"
                v-model="evaluation_info.leadSource"
              ></v-text-field>
              <v-textarea
                label="Lead Comments"
                v-model="evaluation_info.leadComments"
                auto-grow
                outlined
                rows="3"
                row-height="33"
              ></v-textarea>
              <v-text-field
                label="ZEstimate"
                v-model="evaluation_info.ZEstimate"
              ></v-text-field>
              <v-text-field
                label="BRs"
                v-model="evaluation_info.BRs"
              ></v-text-field>
              <v-text-field
                label="Baths"
                v-model="evaluation_info.Baths"
              ></v-text-field>
              <v-text-field
                label="Sq Ft."
                v-model="evaluation_info.sq_ft"
              ></v-text-field>
              <v-text-field
                label="Year Built"
                v-model="evaluation_info.yearBuilt"
              ></v-text-field>
              <v-text-field
                label="Lot Size"
                v-model="evaluation_info.lotSize"
              ></v-text-field>
              <v-text-field
                label="Neighborhood"
                v-model="evaluation_info.Neighborhood"
              ></v-text-field>
              <v-text-field
                label="Length of Ownership"
                v-model="evaluation_info.ownershipLength"
              ></v-text-field>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-checkbox
                    v-model="evaluation_info.basementWalls"
                    label="Cracked basement walls"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-checkbox
                    v-model="evaluation_info.waterInBasement"
                    label="Water in basement"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-checkbox
                    v-model="evaluation_info.stickingDoors"
                    label="Sticking doors"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-checkbox
                    v-model="evaluation_info.stickingWindows"
                    label="Sticking windows"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-checkbox
                    v-model="evaluation_info.SlopingFloors"
                    label="Sloping floors"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-checkbox
                    v-model="evaluation_info.basementDoors"
                    label="Door in basement"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row> </v-row>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-checkbox
                    v-model="evaluation_info.waterInYard"
                    label="Pooling water in my yard"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-checkbox
                    v-model="evaluation_info.concreteSlabs"
                    label="Sinking/uneven concrete slabs"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-text-field
                label="On a scale of 1 to 10, I am worried (10 being worst)"
                v-model="evaluation_info.worried"
              ></v-text-field>
              <v-text-field
                label="I have been dealing with this problem"
                v-model="evaluation_info.problemDealing"
              ></v-text-field>
              <v-row>
                <v-col cols="12" sm="4">
                  <v-checkbox
                    v-model="evaluation_info.otherBids"
                    label="Have you already received other bids?"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-checkbox
                    v-model="evaluation_info.foundationRepairs"
                    label="Have you had foundation repairs previously that did not work?"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-text-field
                label="Which best describes you"
                v-model="evaluation_info.describesYou"
              ></v-text-field>
              <v-textarea
                label="Comments"
                auto-grow
                outlined
                rows="3"
                row-height="33"
                v-model="evaluation_info.comments"
              ></v-textarea>
              <div class="text-right">
                <v-btn color="primary" dark>Submit</v-btn>
              </div>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";
export default {
  name: "EvaluationReschedule.vue",
  components: { CustomerInfo },
  data() {
    return {
      evaluation_id: this.$route.params.id,
      valid: false,
      evaluation_info: {
        username: "JOE CUSTOMER",
        eval_date: "9/1/2020",
        eval_time: "10:00",
        address: "565 Murlen",
        city: "Overland Park, KS",
        phone: "913-242-1234",
        email: "Jcust@gmail.com",
        customerGPA: "A",
        geoZone: "41",
        leadSource: "TV-FOX-WDAF4",
        leadComments: "",
        comments: "",
        ZEstimate: "305205",
        BRs: "4",
        Baths: "3",
        sq_ft: "3245",
        yearBuilt: "1992",
        lotSize: "2075",
        Neighborhood: "Sheffield Estates",
        ownershipLength: "15 years",
        basementWalls: true,
        waterInBasement: true,
        stickingDoors: false,
        stickingWindows: false,
        SlopingFloors: false,
        basementDoors: false,
        waterInYard: false,
        concreteSlabs: false,
        worried: "7",
        problemDealing: "For many years",
        otherBids: true,
        foundationRepairs: false,
        describesYou: "Homeowner",
      },
    };
  },
};
</script>

<style scoped></style>
